import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import VRPic from '../images/VRPic.png'
import { Row, Col } from 'react-bootstrap'
import Meta from '../components/Meta'

const NORMAVERSEPage = ({ match }) => {

  return (
    <>
      <Meta />
        <div className="container-center flex-col">

          <Row>
          <h1>Step Into the NOMAVERSE</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus massa 
                vitae felis sodales, at ultrices tellus lacinia. Pellentesque a odio sollicitudin, 
                iaculis ipsum non, maximus dolor.</p>
          </Row>

          <Row>
            <Col sm={12} md={4} lg={4} xl={4}>
              <h2>INSTALLATION #001</h2>
              <img src={VRPic} />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus massa 
                vitae felis sodales, at ultrices tellus lacinia. Pellentesque a odio sollicitudin, 
                iaculis ipsum non, maximus dolor.</p>
                <a href="https://seasonsix.co/ar-studio" target='_blank' className='btn btn-light my-3'>
                  ENTER
                </a>
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <h2>INSTALLATION #002</h2>
              <img src={VRPic} />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus massa 
                vitae felis sodales, at ultrices tellus lacinia. Pellentesque a odio sollicitudin, 
                iaculis ipsum non, maximus dolor.</p>
                <a href="https://seasonsix.co/ar-studio" target='_blank' className='btn btn-light my-3'>
                  ENTER
                </a>
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <h2>INSTALLATION #003</h2>
              <img src={VRPic} />
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque cursus massa 
                vitae felis sodales, at ultrices tellus lacinia. Pellentesque a odio sollicitudin, 
                iaculis ipsum non, maximus dolor.</p>
                <a href="https://seasonsix.co/ar-studio" target='_blank' className='btn btn-light my-3'>
                  ENTER
                </a>
            </Col>
            
          </Row>
          
        </div>
    </>
  )
}

export default NORMAVERSEPage
