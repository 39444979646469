import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'

import HomeScreen from './screens/HomeScreen'
import CustomPage from './screens/CustomPage'
import NOMAVERSEPage from './screens/NOMAVERSEPage'
import Footage from './screens/Footage'
import Post from './screens/Post'
import SinglePost from './screens/SinglePost'
import NFT from './screens/NFT'
import Pieces from './screens/Pieces'
import PieceDetail from './screens/PieceDetail'

import ScavengerScreen from './screens/ScavengerScreen'
import ScavengerLandingScreen from './screens/ScavengerLandingScreen'

import ShopScreen from './screens/ShopScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import RequestScreen from './screens/RequestScreen'
import ProfileScreen from './screens/ProfileScreen'
import CardScreen from './screens/CardScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import NORMAVERSEPage from './screens/NOMAVERSEPage'

const App = () => {
  return (
    <Router>
      <div className='container'>
        <Header />
        <Switch>
          {/* <main className='py-3'> */}
            {/* <Container> */}
              <Route path='/' component={HomeScreen} exact />
              <Route path='/order/:id' component={OrderScreen} />
              <Route path='/claim-key' component={ScavengerScreen} />
              <Route path='/scavenger' component={ScavengerLandingScreen} />
              <Route path='/shop' component={ShopScreen} />
              <Route path='/nfts' component={NFT} />
              <Route path='/nomaverse' component={NORMAVERSEPage} />
              <Route path='/footage' exact component={Footage} />
              <Route path='/post' exact component={Post} />
              <Route path='/post/:slug' exact component={SinglePost} />
              <Route path='/pieces' exact component={Pieces} />
              <Route path='/pieces/:slug' exact component={PieceDetail} />
              {/* <Route path='/project' component={Project} /> */}
              <Route path='/shipping' component={ShippingScreen} />
              <Route path='/payment' component={PaymentScreen} />
              <Route path='/placeorder' component={PlaceOrderScreen} />
              <Route path='/login' component={LoginScreen} />
              <Route path='/request-access' component={RequestScreen} />
              <Route path='/profile' component={ProfileScreen} />
              <Route path='/card/:id' component={CardScreen} />
              <Route path='/product/:id' component={ProductScreen} />
              <Route path='/cart/:id?' component={CartScreen} />
              <Route path='/admin/userlist' component={UserListScreen} />
              <Route path='/admin/user/:id/edit' component={UserEditScreen} />
              <Route path='/admin/user/create' component={RegisterScreen} />
              <Route
                path='/admin/productlist'
                component={ProductListScreen}
                exact
              />
              <Route
                path='/admin/productlist/:pageNumber'
                component={ProductListScreen}
                exact
              />
              <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
              <Route path='/admin/orderlist' component={OrderListScreen} />
              <Route path='/search/:keyword' component={HomeScreen} exact />
              <Route path='/page/:pageNumber' component={HomeScreen} exact />
              <Route
                path='/search/:keyword/page/:pageNumber'
                component={HomeScreen}
                exact
              />
            {/* </Container> */}
          {/* </main> */}
        </Switch>
      <Footer />
      </div>
    </Router>
  )
}

export default App
