import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Card } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { getUserDetails, updateUserProfile } from '../actions/userActions'

const CardScreen = ({ location, history }) => {

  const { id } = useParams()

  const [cardInfo, setCardInfo] = useState('');

  useEffect(() => {
    console.log(id);
    fetch('/api/users/card/' + id)
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setCardInfo(data)
    })
  }, [])


  return (
    <>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <h2>Card Information</h2>
            <Card
              bg={'dark'}
              key={1}
              text={'light'}
              style={{ width: '100%' }}
              className="mb-2"
            >
              <Card.Header>NNY Collector</Card.Header>
              <Card.Body>
                <Card.Title>{cardInfo._id}</Card.Title>
                <Card.Text>
                    Name: {cardInfo.name}<br/>
                    Email: {cardInfo.email}<br/>
                    Access: {cardInfo.eventAccess}
                </Card.Text>
              </Card.Body>
            </Card>
        </Col>
      </Row>
    </>
  )
}

export default CardScreen
