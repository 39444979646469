import React, { useState, useEffect } from "react"
import sanityClient from "../client.js"
import { Link } from 'react-router-dom'

const Post = () => {

    const [postData, setPostData] = useState(null);

    useEffect(() => {
        sanityClient.
            fetch(`*[_type == "post"]{
                title,
                slug,
                mainImage {
                    asset->{
                        _id,
                        url
                    },
                    alt
                },
                body
            }`)
            .then((data) => setPostData(data))
            .catch(console.error);
    }, [])

    return (
        <main>
            <section>
                {/* <h1>Blog Posts Page</h1>
                <h2>Welcome to the blog</h2> */}
                <div>
                    {postData && postData.map((post, index) => (
                    <article className="archive-post">
                        
                            {/* <span key={index}> */}
                                <div className="article" key={index}>
                                    <div className="title">
                                        <Link to={"/post/" + post.slug.current} key={post.slug.current}>
                                            <h3>{post.title}</h3>
                                        </Link>
                                    </div>
                                    <div className="body">
                                        <img src={post.mainImage.asset.url} alt={post.mainImage.alt} className='post-thumbnail' />
                                        <p>
                                            {post.body[0].children[0].text}
                                            <br/>
                                            <br/>
                                            <Link to={"/post/" + post.slug.current} key={post.slug.current}>
                                                <span>Read More . . . </span>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            {/* </span> */}
                    </article>
                    ))}
                </div>
            </section>
        </main>
    )
}

export default Post