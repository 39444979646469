import React from 'react'
import { useSelector } from 'react-redux'
import homePic from '../images/homepic.png'
import { Row } from 'react-bootstrap'
const HomeScreen = ({ match }) => {

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <>
      {userInfo ? (
        <>
          <Row>
            <div className="column">
              <img className="w-full" src={homePic} />
            </div>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <div className="column">
              <img className="w-full" src={homePic} />
            </div>
          </Row>
        </>
      )}
    </>
  )
}

export default HomeScreen
