import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import sanityClient from "../client.js"
import { Link } from 'react-router-dom'
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const SinglePost = () => {

    const [singlePostData, setSinglePostData] = useState(null);
    const {slug} = useParams();

    useEffect(() => {
        sanityClient.
            fetch(`*[slug.current == "${slug}"]{
                title,
                _id,
                slug,
                mainImage {
                    asset->{
                        _id,
                        url
                    }
                },
                body,
                "name": author->name,
                "authorImage": author->image
            }`)
            .then((data) => setSinglePostData(data[0]))
            .catch(console.error);
    }, [slug])

    if (!singlePostData) return <div>Loading . . .</div>

    return (
        <main>
            <article>
                <header>
                    {/* <div> */}
                        {/* <div> */}
                            {/* <div>
                                <img src={urlFor(singlePostData.authorImage).url()} alt={singlePostData.name} width="200" />
                                <p>{singlePostData.name}</p>
                            </div> */}
                        {/* </div> */}
                    {/* </div> */}
                    <h1>{singlePostData.title}</h1>
                </header> 
                <div className="post-body">
                    <img src={singlePostData.mainImage.asset.url} alt={singlePostData.title} className="main-image"/>
                    <BlockContent blocks={singlePostData.body} projectId="84na46q4" dataset="production" className="inner-body"/>
                </div>               
            </article>
        </main>
    )
}

export default SinglePost