import React, { useState, useEffect } from "react"
import sanityClient from "../client.js"
import { Link } from 'react-router-dom'

const NFT = () => {

    const [nftData, setNftData] = useState(null);

    useEffect(() => {
        sanityClient.
            fetch(`*[_type == "nft"]{
                title,
                mainImage {
                    asset->{
                        _id,
                        url
                    },
                    alt
                },
                date,
                description,
                nftType,
                link,
                tags
            }`)
            .then((data) => setNftData(data))
            .catch(console.error);
    }, [])

    return (
        <main>
            <section>
                <h1>NFT's</h1>
                <section className="nft-section">
                    {nftData && nftData.map((nft, index) => (
                    <div className="nft">
                        <div>
                            <img src={nft.mainImage.asset.url} alt={nft.mainImage.alt} className="nft-pic" /><br/>
                            {/* <span>Date: {new Date(nft.date).toLocaleDateString()}</span><br/> */}
                            {/* <span>Type: {nft.nftType}</span> */}
                            <h3>
                                <a 
                                href={nft.link}
                                alt={nft.title}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                    {nft.title}
                                </a>
                            </h3>
                            <p>
                                {nft.description}
                            </p>
                            <a href={nft.link} rel="noopener noreferrer" target="_blank">
                                View on OpenSea
                            </a>
                        </div>
                    </div>
                    ))}
                </section>
            </section>
        </main>
    )
}

export default NFT