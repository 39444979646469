import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import sanityClient from "../client.js"
// import { Link } from 'react-router-dom'
import imageUrlBuilder from "@sanity/image-url"
// import BlockContent from "@sanity/block-content-to-react"

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const PieceDetail = ({history}) => {

    const [singlePieceData, setSinglePieceData] = useState(null);
    const [pieces, setPieces] = useState(null)
    const {slug} = useParams();

    const dispatch = useDispatch()

    const userDetails = useSelector((state) => state.userDetails)
    const { user } = userDetails

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin  
  
    const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
    const { success } = userUpdateProfile

    useEffect(() => {

        if (!userInfo) {
            history.push('/login')
        } else {
            if (!user || !user.name || success) {
              dispatch({ type: USER_UPDATE_PROFILE_RESET })
              dispatch(getUserDetails('profile'))
            } else {
                console.log("useEffect ran")
                setPieces(user.pieces)
                console.log("user.pieces:", pieces)
            }
        }

        sanityClient.
            fetch(`*[slug.current == "${slug}"]{
                title,
                collection,
                year,
                description,
                pieceType,
                pieceID,
                mainImage {
                    asset->{
                        _id,
                        url
                    },
                    alt
                },
                imagesGallery[]{
                    asset->{
                        url
                    },
                tags
                },
            }`)
            .then((data) => setSinglePieceData(data[0]))
            .catch(console.error);
                
    }, [success])

    console.log("user:", user)

    if (!singlePieceData) return <div>Loading . . .</div>

    function switchImage(image) {
        document.getElementById("mainGalleryImage").src = image.target.src;
    }

    function addPiece(item) {
        let newPieces = [...user.pieces, item];
        console.log("New Pieces:", newPieces)
        let pieces = newPieces;
        dispatch(updateUserProfile({ id: user._id, pieces}))
    }

    if (!user.pieces) {
        console.log("undefined:",user.pieces)
    }

    let button;
    if (user.pieces.includes(singlePieceData.pieceID)) {
        button = <button className="btn btn-black">IN COLLECTION</button>;    
    } else {      
        button = <button className="btn btn-black" onClick={() => addPiece(singlePieceData.pieceID)}>ADD TO COLLECTION</button>;  
    } 

    let images;
    if (singlePieceData.imagesGallery) {
        images = singlePieceData.imagesGallery.map((image,index) => <img src={image.asset.url} height="75" key={index} cleacleagit="true" onClick={(e) => switchImage(e)}/>)
    } else {
        images = <div></div>
    }

    return (
        <main className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
                <img src={singlePieceData.mainImage.asset.url} alt={singlePieceData.title} id="mainGalleryImage" />
                <div className="grid grid-cols-6 gap-4">
                    {images}
                </div>
            </div>

            <div>
                <h1>{singlePieceData.title}</h1>
                <p>Collection: {singlePieceData.collection}</p>
                <p>Year: {singlePieceData.year}</p>
                <p>Description: {singlePieceData.description}</p>
                <p>Piece ID: {singlePieceData.pieceID}</p>

                {button}
            </div>

            {/* <div><BlockContent blocks={singlePieceData.body} projectId="84na46q4" dataset="production"/></div>                */}
        </main>
    )
}

export default PieceDetail