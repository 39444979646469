import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import jacket from '../images/jacket.png'
import pj3d from '../images/3dobjects/NNY_PJ.usdz'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import sanityClient from "../client.js"
import { Link } from 'react-router-dom'

const ProfileScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [shoeSize, setShoeSize] = useState('')
  const [pieces, setPieces] = useState(null)
  const [shirtSize, setShirtSize] = useState('')
  const [bottomSize, setBottomSize] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)
  const [pieceData, setPieceData] = useState(null);

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const orderListMy = useSelector((state) => state.orderListMy)
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails('profile'))
        dispatch(listMyOrders())
      } else {
        setName(user.name)
        setEmail(user.email)
        setPieces(user.pieces)
        setShoeSize(user.shoeSize)
        setShirtSize(user.shirtSize)
        setBottomSize(user.bottomSize)
        setEmail(user.email)
      }
    }

    const query = `*[_type == "piece" && pieceID in $userPieces]{
      title,
      slug,
      pieceID,
      mainImage {
          asset->{
              _id,
              url
          },
          alt
      },
  }`
const params = {
  userPieces: user.pieces//insert your MDB array here
}

sanityClient.
          fetch(query, params)
          .then(setPieceData)
          .catch(console.error);
          
  }, [dispatch, history, userInfo, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, shoeSize, shirtSize, bottomSize, password }))
    }
  }

  return (
    <>
      <Row>
        <Col xl={12} lg={12}>
          <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="edit" title="Edit Profile">
                <h2>User Profile</h2>
              {message && <Message variant='danger'>{message}</Message>}
              {}
              {success && <Message variant='success'>Profile Updated</Message>}
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'>{error}</Message>
              ) : (
                <Row>
                  <Col xl={6}>
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId='name'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type='name'
                          placeholder='Enter name'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          type='email'
                          placeholder='Enter email'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='shoes'>
                        <Form.Label>Shoe Size</Form.Label>
                        <Form.Control
                          type='shoe'
                          placeholder='Enter shoe size'
                          value={shoeSize}
                          onChange={(e) => setShoeSize(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='shirt'>
                        <Form.Label>Shirt Size</Form.Label>
                        <Form.Control
                          type='shirt'
                          placeholder='Enter shirt size'
                          value={shirtSize}
                          onChange={(e) => setShirtSize(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='bottom'>
                        <Form.Label>Bottom Size</Form.Label>
                        <Form.Control
                          type='bottom'
                          placeholder='Enter bottom size'
                          value={bottomSize}
                          onChange={(e) => setBottomSize(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type='password'
                          placeholder='Enter password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group controlId='confirmPassword'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type='password'
                          placeholder='Confirm password'
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Button type='submit' variant='primary'>
                        Update
                      </Button>
                    </Form>
                  </Col>
                </Row>
                
              )}
            </Tab>
            <Tab eventKey="orders" title="Orders">
                <h2>My Orders</h2>
              {loadingOrders ? (
                <Loader />
              ) : errorOrders ? (
                <Message variant='danger'>{errorOrders}</Message>
              ) : (
                <Table striped bordered hover responsive className='table-sm'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DATE</th>
                      <th>TOTAL</th>
                      <th>PAID</th>
                      <th>DELIVERED</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr key={order._id}>
                        <td>{order._id}</td>
                        <td>{order.createdAt.substring(0, 10)}</td>
                        <td>{order.totalPrice}</td>
                        <td>
                          {order.isPaid ? (
                            order.paidAt.substring(0, 10)
                          ) : (
                            <i className='fas fa-times' style={{ color: 'red' }}></i>
                          )}
                        </td>
                        <td>
                          {order.isDelivered ? (
                            order.deliveredAt.substring(0, 10)
                          ) : (
                            <i className='fas fa-times' style={{ color: 'red' }}></i>
                          )}
                        </td>
                        <td>
                          <LinkContainer to={`/order/${order._id}`}>
                            <Button className='btn-sm' variant='light'>
                              Details
                            </Button>
                          </LinkContainer>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Tab>
            <Tab eventKey="closet" title="Closet">
                <h2>My Closet</h2>
              {loadingOrders ? (
                <Loader />
              ) : errorOrders ? (
                <Message variant='danger'>{errorOrders}</Message>
              ) : (
                //  <!-- 3D objsect -->
                //  This inserts the 3D object inside the aside container -->

                
                <>
     
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {pieceData && pieceData.map((piece, index) => (

                    <div className="piece justify-center">
                      <Link to={"/pieces/" + piece.slug.current} key={piece.slug.current}>
                        <img src={piece.mainImage.asset.url} alt={piece.mainImage.alt} />
                      </Link>
                        
                      <div>
                        <h4 className="piece">{piece.title}</h4>
                        <span>PieceID: {piece.pieceID}</span>
                      </div>
      
                    </div>
                    ))}
                  </div>
                </>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default ProfileScreen
