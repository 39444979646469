import React, { useState, useEffect } from "react"
import sanityClient from "../client.js"
import { Link } from 'react-router-dom'
import BlockContent from "@sanity/block-content-to-react"

const Footage = () => {

    const [footageData, setFootageData] = useState(null);

    useEffect(() => {
        sanityClient.
            fetch(`*[_type == "footage"]{
                title,
                slug,
                body
            }`)
            .then((data) => setFootageData(data))
            .catch(console.error);
    }, [])

    return (
        <main>
            {/* <section>
                <div>
                    {footageData && footageData.map((footage, index) => (
                    <article>
                            <span key={index}>
                                <span>
                                    <h3>{footage.title}</h3>
                                </span>
                                <BlockContent blocks={footage.body} projectId="84na46q4" dataset="production" className="inner-body"/>
                            </span>
                    </article>
                    ))}
                </div>
            </section> */}
            <section className="video-footage">
                    <span>
                        <h3>Anchors 3</h3>
                        <p>
                            Salt Lake City : Tide 3
                            <br/>
                            Shot by: Ceo Jay
                        </p>
                    </span>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/600587421?h=2122043dbc" width="640" height="360" frameborder="0" allowfullscreen></iframe>             
            </section>
            <section className="video-footage">
                    <span>
                        <h3>Aesthete Collection</h3>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget neque eget libero viverra accumsan. Morbi egestas neque nibh, quis consequat est convallis nec.</p> */}
                    </span>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/467960829?h=6939976c34" width="640" height="360" frameborder="0" allowfullscreen></iframe>
            </section>
            <section className="video-footage">
                    <span>
                        <h3>NYFW NOCTURNAL BEING</h3>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean eget neque eget libero viverra accumsan. Morbi egestas neque nibh, quis consequat est convallis nec.</p> */}
                    </span>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/363062111?h=983403a7f0" width="640" height="360" frameborder="0" allowfullscreen></iframe>
            </section>
        </main>
    )
}

export default Footage