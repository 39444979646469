import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Message from '../components/Message'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'

const RegisterScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [shoeSize, setShoeSize] = useState('')
  const [shirtSize, setShirtSize] = useState('')
  const [bottomSize, setBottomSize] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  // const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    // if (userInfo) {
    //   history.push(redirect)
    // }
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      setMessage('User has been successfully created!')
      dispatch(register(name, email, password));
    }
  }

  return (
    <>
      <Link to='/admin/userlist' className='btn btn-light my-3'>
        Go Back
      </Link>
    <Row>
      <h1>Create User</h1>
    </Row>
    <Row>
      <Col sm={12} md={12} lg={6} xl={6}>
        <FormContainer>
          {message && <Message variant='warning'>{message}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            
            <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='confirmPassword'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {/* <Form.Group controlId='shoe'>
              <Form.Label>Shoe Size</Form.Label>
              <Form.Control
                type='shoe'
                placeholder='e.g 8.5'
                value={shoeSize}
                onChange={(e) => setShoeSize(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='shirt'>
              <Form.Label>Shirt Size</Form.Label>
              <Form.Control
                type='shirt'
                placeholder='e.g Medium'
                value={shirtSize}
                onChange={(e) => setShirtSize(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='shoe'>
              <Form.Label>Bottom Size</Form.Label>
              <Form.Control
                type='bottom'
                placeholder='e.g 34'
                value={bottomSize}
                onChange={(e) => setBottomSize(e.target.value)}
              ></Form.Control>
            </Form.Group> */}

            <Button type='submit' variant='primary'>
              Create User
            </Button>
          </Form>

        </FormContainer>

      </Col>
    </Row>
    </>
  )
}

export default RegisterScreen
