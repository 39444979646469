import React, { useState, useEffect } from "react"
import sanityClient from "../client.js"
import { Link } from 'react-router-dom'

const Pieces = () => {

    const [pieceData, setPieceData] = useState(null);

    useEffect(() => {
        sanityClient.
            fetch(`*[_type == "piece"]{
                title,
                slug,
                collection,
                mainImage {
                    asset->{
                        _id,
                        url
                    },
                    alt
                },
            }`)
            .then((data) => setPieceData(data))
            .catch(console.error);
    }, [])

    return (
        <main>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {pieceData && pieceData.map((piece, index) => (
                    <div className="piece justify-center" key={index}>
                            <Link to={"/pieces/" + piece.slug.current} key={piece.slug.current}>
                                <img src={piece.mainImage.asset.url} alt={piece.mainImage.alt} />
                            </Link>
                        
                            <div>
                            <h4 className="piece">{piece.title}</h4>
                            <span>Collection: {piece.collection}</span>
                            </div>
                            
                          
                    </div>
                    ))}
                </div>
              


        </main>
    )
}

export default Pieces