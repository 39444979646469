import React, { useState, useEffect } from 'react'
import { Form, Button} from 'react-bootstrap'
import FormContainerScavenger from '../components/FormContainerScavenger'
import background from '../images/background-2.png'

const ScavengerScreen = () => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [city, setCity] = useState('')
  const [street, setStreet] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipCode] = useState('')


  useEffect(() => {
    if (window.location.href.indexOf("claim-key") > -1) {
        document.getElementsByClassName('navbar')[0].style.display ="none";
        document.body.style.backgroundImage = `url(${background})`;
        document.body.style.backgroundSize = "cover";
    }
  });

  const submitHandler = (e) => {
    e.preventDefault()
  }

  return (
    <FormContainerScavenger>
      <h1 className='text-center mt-10'>CONGRATS, YOU FOUND THE KEY 🖤🗝️</h1>
      <h2 className='text-center'> 02 / 03 </h2>
      <br/>
      <br/>
      <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ullamcorper dapibus facilisis.</p>
      <p className='text-center'>Fill out the form to recieve your Golden Necklace</p>
      <br/>
      <br/>
      <br/>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId='name'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter your name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>Street</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter street'
            value={street}
            onChange={(e) => setStreet(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='City'
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='state'>
          <Form.Label>State</Form.Label>
          <Form.Control
            type='text'
            placeholder='State'
            value={state}
            onChange={(e) => setState(e.target.value)}
          ></Form.Control>
        </Form.Group>
       
        <Form.Group controlId='zipcode'>
          <Form.Label>ZIP Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='ZIP Code'
            value={zipcode}
            onChange={(e) => setZipCode(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Submit
        </Button>
      </Form>

     
    </FormContainerScavenger>
  )
}

export default ScavengerScreen
