import React from 'react'
import { Row, Col } from 'react-bootstrap'
import leftPic from '../images/guild-2.jpeg'

const RequestScreen = ({ location, history }) => {

  const submitHandler = (e) => {
    e.preventDefault()

    document.getElementById("thank-you").style.display = "block";
    document.getElementById("register-form").style.display = "none";
  }

  return (
    <>
    <Row className='justify-content-center'>
      <h1>Request Access</h1>
    </Row>
    <Row>
      
      <Col sm={12} md={12} lg={6} xl={6}>

      <img src={leftPic} style={{width: "100%", marginBottom: "25px", objectFit: "cover"}} />
      </Col>
      <Col sm={12} md={12} lg={6} xl={6}>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXAti7H4zowAC1PfYAcWC-muXsmW8cTcXAfZwbLZfCys1sQA/viewform?embedded=true" width="100%" height="947" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </Col>
    </Row>
    </>
  )
}

export default RequestScreen
