import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer style={{position: "relative", bottom: "0", width: "100%", color: "#000"}}>
      {/* <Container> */}
        <Row>
          <Col className='text-center py-3 mt-10'>Copyright &copy; NOMA New York // Built by SEASON SIX</Col>
        </Row>
      {/* </Container> */}
    </footer>
  )
}

export default Footer
