import React, {useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import background from '../images/background-2.png'
import goldkey from '../images/goldkey.png'
import instructions from '../images/instructions.jpg'
import one from '../images/1.png'
import two from '../images/2.png'
import three from '../images/3.png'
import four from '../images/4.png'
import FormContainerScavengerLanding from '../components/FormContainerScavengerLanding'

const ScavengerLandingScreen = () => {

  useEffect(() => {
    if (window.location.href.indexOf("scavenger") > -1) {
        document.getElementsByClassName('navbar')[0].style.display ="none";
        document.body.style.backgroundImage = `url(${background})`;
        document.body.style.backgroundSize = "cover";
    }
  });

  return (
    <FormContainerScavengerLanding>
      <h1 className='text-center bold mt-10'>FIND THE KEY / COLLECT THE KEY</h1>
      <h2 className='text-center bold'> 02 / 02 CLAIMED</h2>
      <br/>
      <br/>

      <p className='text-center p-0 bold'>All keys have been found. The hunt is now over.</p>
      <p className='text-center p-0 bold'>Two Gold Keys</p>
      <p className='text-center p-0 bold'>You Found Them, They're Yours To Keep</p>
      <br/>
      <br/>

      <Row className='justify-content-md-center'>
        <Col xs={12} md={6} lg={6} className="mb-20">
          <img src={instructions} alt="Instructions" />
        </Col>
        <Col xs={12} md={6} lg={6} className="mb-20">
          <img src={goldkey} alt="Instructions" />
        </Col>
      </Row>
      <br/>
      <br/>

      <Row className='justify-content-md-center'>
        <Col xs={12} md={12} lg={6}>
          <p className='text-center bold'>LITTLE ISLAND<br/>SATURDAY JULY 23, 2022<br/>PIER 55 AT HUDSON RIVER PARK<br/>NY, NY 10014</p>
        </Col>
        <Col xs={12} md={12} lg={6}>
          <p className='text-center bold'>P96 SUMMER EXPERIENCE<br/>SUNDAY JULY 24, 2022<br/>2PM - 8PM<br/>BEACH 73RD ST<br/>QUEENS, NY 11692</p>
        </Col>
      </Row>
      <br/>
      <br/>

      <Row className='justify-content-md-center'>
        <Col xs={6} md={6} lg={3} className="mb-20">
          <img src={one} alt="Instructions" />
        </Col>
        <Col xs={6} md={6} lg={3} className="mb-20">
          <img src={two} alt="Instructions" />
        </Col>
        <Col xs={6} md={6} lg={3} className="mb-20">
          <img src={three} alt="Instructions" />
        </Col>
        <Col xs={6} md={6} lg={3} className="mb-20">
          <img src={four} alt="Instructions" />
        </Col>
      </Row>

      <br/>
      <br/>

      <h2 className='text-center'>HINTS</h2>
      <p> P96 Summer Experience. Multiple flags will be placed in the sand. Pick the right flag and you might get lucky 🍀 </p>
      <br/>

    </FormContainerScavengerLanding>
  )
}

export default ScavengerLandingScreen
